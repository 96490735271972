import React, { useContext, useEffect, useState } from "react";
import NavigationBar from "../../Components/NavigattionBar/NavigationBar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { useFilters, useGlobalFilter, usePagination, useTable } from "react-table";
import "./BrokerScreen.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from "../../Components/Footer/Footer";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useNavigate } from "react-router-dom";
import AddBrokerModal from "../../Components/AddBrokerModal/AddBrokerModal";
import { UserContext } from "../../context/userContext";
import Drawer from "../../Components/drawer/Drawer";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { BASE_URL } from "../../assets/ApiUrls";
import { CircularProgress } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const columns = [
  {
    Header: "BROKER NAME",
    accessor: "brokerName"
  },
  {
    Header: "EMAIL",
    accessor: "brokerEmail"
  },
  {
    Header: "LOCATION",
    accessor: "location"
  },
  {
    Header: "SPENT",
    accessor: "spent",
    Cell: ({ value }) => `$ ${value}`},
  {
    Header: "CONTACT NO",
    accessor: "brokerPhone",
    Cell: (
      { row } // Render custom cell
    ) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "4px" }}>+1</div>
        {row.original.brokerPhone} {/* Render customer name */}
      </div>
    ),
  },
  {
    Header: () => <AccessTimeIcon/>,
    accessor: "createdAt",
    Cell: ({ value }) => new Date(value).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    })
  }
  
  ]
  
const BrokerScreen = () => {
  const {drawer} = useContext(UserContext);
  const navigator = useNavigate();
  const [filterInput, setFilterInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedBrokers, setSelectedBrokers] = useState([]);
  const [isDeleteClicked, setIsdeleteClicked] = useState(false);
  const [ hoveredRow , setHoveredRow] = useState(null);
  const [brokerData , setBrokerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data : brokerData,
      initialState: { pageIndex: 0 }, // Start from the first page
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );

  useEffect(() => {
    console.log("UseEffect Called -- ")
    fetchBrokers()
  }, []);

  const fetchBrokers = async () => {
    setIsLoading(true)
    const storedUserId = localStorage.getItem("user_id");
    try {
      const response = await fetch(`${BASE_URL}brokers/getBrokers?user_id=${storedUserId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch brokers: ${response.status} ${response.statusText}`);
      }
      const result = await response.json(); // Parse response as JSON
      console.log("Fetched brokers:", result.status); // Check the structure of result
      
      // Check if 'data' property exists and is an array
      if (result.status == 1) {
        setBrokerData(result.data); // Append new data to existing data
      } else {
        console.error("Invalid response format:", result);
      }
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }finally{
      setIsLoading(false)
    }
  };

  const renderSelectionButton = () => {
    return (
     
        <input type="checkbox" />
    );
  };

  const handleConfirmDeleteClick = () => {
    // Delete selected brokers
    // Implement your logic to delete brokers based on selectedBrokers state
    console.log("Selected Leads to delete :", selectedBrokers);
    // Clear selected brokers after deletion
    
    deleteBrokers([...selectedBrokers]);
    setSelectedBrokers([]);
  };

  async function deleteBrokers(dataArray) {
    console.log("DataArray -- " , dataArray[0])
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(dataArray),
      redirect: "follow"
    };
  
    try {
      const response = await fetch(
        `${BASE_URL}brokers/deleteBroker`,
        requestOptions
      );
      const myResponse = await response.json();
      console.log("Leads -- " , myResponse.status)
      if(myResponse.status === 1){
        console.log("Status 1 is clicked --- ")
        setIsdeleteClicked(false)
        fetchBrokers();
      }// You can return the result if needed
      else{
        alert("Deletion Failed!")
      }
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error for handling at the caller side
    }
  }

  

  const handleDeleteClick =() =>{
    setIsdeleteClicked(!isDeleteClicked)
  }

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );
  const handleRowClick = (row) => {
    console.log("Row clicked:" , row.original._id);
    navigator('/BrokerDetailScreen', { state: { brokerId: row.original._id } });
    // Add your logic here to handle row click
  };

  const toggleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      const selectedRows = brokerData.map((lead) => {
        const { user_id: userId, brokerEmail: brokerEmail } = lead;
        return { userIds: userId, brokerEmails: brokerEmail };
      });
      setSelectedBrokers(selectedRows);
    } else {
      setSelectedBrokers([]);
    }
  };

  const toggleSelectBroker = (broker) => {
    const { user_id: userId, brokerEmail: brokerEmail } = broker;

    const isSelected = selectedBrokers.some((broker) => broker.brokerEmails === brokerEmail);
  
    if (!isSelected) {
      
    setSelectedBrokers([...selectedBrokers, { userIds: userId, brokerEmails: brokerEmail }]);
    } else {
      setSelectedBrokers(selectedBrokers.filter((broker) => broker.brokerEmails !== brokerEmail));
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    console.log("close ")
    setIsModalOpen(false);
  };

  return (
    <div>
    { drawer ? <Drawer /> : 
    <div style={{ display: 'flex', flexDirection: 'column',  overflowX: 'auto'  , height: '150vh' , backgroundColor:'#F8F8F8' }}>
      <NavigationBar tab={2}/>
      {isLoading ? ( // Conditional rendering based on loading state
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress /> {/* Display CircularProgress component while loading */}
        </div>
      ) : (
      <div style={{paddingBottom: '5vh' , paddingInline: '40px' , flex: 1,  backgroundColor: '#FFFFFF' , width: '90%' , alignSelf: 'center' , marginTop: '5vh' , borderRadius: '20px' , marginBottom: '5vh', overflowX:'auto'}}>
      <div style={{display: 'flex' , flexDirection: 'row' , alignItems: 'center' , justifyContent: 'space-between'}}>
        <div style={{display: 'flex' , flexDirection: 'row' , alignItems: 'center'}}>
        <h2 style={{fontWeight: '600'}}>Brokers</h2>
        {
        isDeleteClicked  && selectedBrokers.length > 0?
        <div style={{color: '#D68B43' , marginLeft: '10px' , fontSize: '18px'}}>{'['}{selectedBrokers.length}{' selected'}{']'}</div>:
        null
        }
        </div>
        <input
          value={filterInput}
          onChange={handleFilterChange}
          placeholder={"Search Brokers by name"}
          style={{ marginBottom: "1rem" , width: '25rem' , border: "1px solid #9E9E9E" ,paddingTop: '13px' , paddingLeft: '20px' , marginTop: '20px' , marginLeft: '40px'}}
        />
        <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'center'}}>
        <div  onClick={handleDeleteClick} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , cursor: 'pointer'}}>
      <div style={{fontSize: '14px' , marginLeft: '5px'}}>{isDeleteClicked ? 'Cancel' : 'Delete Broker'}</div>
      
      </div>
      {
        isDeleteClicked ? 
        <div onClick={handleConfirmDeleteClick} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , marginLeft: '10px' ,  cursor: 'pointer' , backgroundColor: '#f2dcc6' }}>
       <div>Confirm Delete</div>
       <SendOutlinedIcon style={{ marginLeft: '15px', height: '20px' , color: '#21272A' }} />
        
        </div>
        :
      <div onClick={openModal} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , marginLeft: '10px' ,  cursor: 'pointer' }}>
      <PlaylistAddIcon style={{ marginInline: '5px', height: '25px' , color: '#21272A' }} />
      <div>Add Broker</div>
      
      </div>
      }
      </div>
        </div>
        <AddBrokerModal isOpen={isModalOpen} onClose={closeModal} onSubmit={fetchBrokers} />
        <table style={{ width: "100%", paddingLeft: '500px' }} {...getTableProps()}>
  <thead>
    {headerGroups.map((hg) => (
      <tr {...hg.getHeaderGroupProps()} style={{ position: 'relative', whiteSpace: 'nowrap', fontWeight: '400' }}>

        {hg.headers.map((header, index) => (
          <th {...header.getHeaderProps()} style={{ position: 'relative', whiteSpace: 'nowrap', fontWeight: '400' , paddingLeft: index === 0   ? '50px' :  index === 5 ?'20px': '0px' }}>
            {index === 0 && isDeleteClicked ? (
              <div style={{ display: 'flex', alignItems: 'center', width: '18px' ,  marginLeft: '-30px' }}>
                <input checked={selectAllChecked}
                 onChange={() => toggleSelectAll()} type="checkbox" style={{marginRight: '32px' , borderRadius: '50%'}}/>
                {header.render('Header')}
              </div>
            ) : (
              header.render('Header')
            )}
          </th>
        ))}
      </tr>
    ))}
  </thead>
            {
              brokerData.length ==0 ? <div style={{height: '50vh', width: '90%' , paddingTop: '10%' , position: 'absolute' ,  textAlign: 'center' , alignSelf: 'center' , alignItems: 'center' , justifyContent: 'center' , fontSize: '17px'}}>
              <InfoOutlinedIcon style={{color: 'black' , height: '14px'}}></InfoOutlinedIcon> No Data
            </div>:
  <tbody {...getTableBodyProps()}>

    {page.map((row, index) => {
      prepareRow(row);

      return (
        <tr
          {...row.getRowProps()}
          onClick={() => {
            if (!isDeleteClicked) {
              handleRowClick(row);
            }
          }}
          onMouseEnter={(e) => {
            setHoveredRow(index)
          }}
          onMouseLeave={(e) => {
            setHoveredRow(null)
          }}
          style={{ backgroundColor: hoveredRow == index ? '#f2dcc6' : 'transparent' }}
        >
          {row.cells.map((cell, index) => (
            <td {...cell.getCellProps()} style={{ position: 'relative',  paddingLeft: index === 0 ? '50px' : '0' }}>
              {index === 0 && isDeleteClicked && (
                <div style={{ position: 'absolute', left: '0', top: '54%', transform: 'translateY(-50%)' }}>
                  <input type="checkbox" checked={selectedBrokers.some((brokers) => brokers.brokerEmails === row.original.brokerEmail)}
                       onChange={() => toggleSelectBroker(row.original)}
                       />
                </div>
              )}
              <div style={{ marginLeft: index === 0 && isDeleteClicked ? '50px' : '0' }}>
                {cell.render('Cell')}
              </div>
            </td>
          ))}
        </tr>
      );
    })}
  </tbody>
}

  
</table>

        <div style={{  display: "flex", justifyContent: "center" , alignItems: 'center'  , marginTop: brokerData.length ==0 ? '300px': '20px'}}>
          <span style={{border: "1px solid #C4CDD5" ,display: 'flex' ,backgroundColor: canPreviousPage ? '#919EAB' : 'white'  , borderRadius: '6px' , paddingInline: '5px' , paddingBlock: '7px' , alignItems: 'center' , justifyContent: 'center' , marginRight: '10px'}} onClick={() => previousPage()} disabled={!canPreviousPage}>
            <ArrowBackIosIcon style={{marginLeft: '8px' , color: '#C4CDD5'}} />
          </span>{" "}
          <span>
            Page{" "}
            <strong>
              {state.pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span style={{ border: "1px solid #C4CDD5" , marginLeft: '10px' , display: 'flex' ,backgroundColor: canNextPage ? '#919EAB' : 'white' , borderRadius: '6px' , paddingInline: '5px' , paddingBlock: '7px' , alignItems: 'center' , justifyContent: 'center'}} onClick={() => nextPage()} disabled={!canNextPage}>
            <ArrowForwardIosIcon style={{marginLeft: '8px' , color: '#C4CDD5'}} />
          </span>{" "}
        </div>
      </div>)}
      <Footer style={{ marginTop: 'auto' }} />
    </div>}
    </div>
  );
};

export default BrokerScreen;
