import React, { useState } from 'react';
import '../RegisterPage/RegisterPage.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
import {emailValidator} from "../../../utils/emailValidator";
import { phoneValidator } from '../../../utils/phoneValidator';
import { BASE_URL } from '../../../assets/ApiUrls';
function RegistrationPage() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = async () => {
    console.log("Called --- ")
    try {
      if (firstName.trim() === "" || lastName.trim() === "" || email.trim() === ""|| phone.trim() === "") {
        alert("All Fields are required!");
        return;
      }else if(emailValidator(email) !== ''){
         alert(emailValidator(email));
         return;
      }else if(phoneValidator(phone)){
        alert('Enter Valid Number');
        return;
      }

      setIsLoading(true)
      const response = await fetch(`${BASE_URL}users/sendOTP?email=${email}&phone=${phone}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("response " , response)
      if (!response.ok) {
        throw new Error('Failed to mark payment as paid');
      }
      const userData = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone
      };
      navigate("/otpPage", { state: userData } );
    } catch (error) {
      console.error(error);
    }
    finally{
      setIsLoading(false)
    }
  };
  return (
    <div className="registration-container">
        <div className="headingStyle">
      <h1 className="admin">{'Admin  '}</h1>
      <h1 className='reg'>Registration</h1>
      </div>
      <p className='p'>Get Access to high standards industry leads</p>
      <div className='reg-div-wrapper'>
      <div className="row">
        <div className="input-group">
          <input type="text" id="firstName" placeholder='Enter First Name' name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)}  />
        </div>
        <div className="input-group">
          <input type="text" id="lastName" placeholder='Enter Last Name' name="lastName"value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
      </div>
      <div className="row">
        <div className="input-group">
          <input type="email" id="email" placeholder='yourmail@company.com' name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="input-group">
          <input maxLength={10} type="tel" id="phone" placeholder='Phone' name="phone"  value={phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
      </div>
      </div>
      <button style={{cursor: "pointer"}}  type="submit" onClick={handleRegister}disabled={isLoading}>{isLoading ? "Sending OTP.." : "PROCEED"}</button>
      <div className='belowButton'>
  <p className='reg-p-tag'>
    By proceeding you agree to our 
    <a href="#" className="link">Terms of Use</a> 
    and confirm you have read our 
    <a href="#" className="link">Privacy and Cookie Statement</a>.
  </p>
  <p>
    Already have an account ? 
    <a href=" /Login" className="link">Login</a> 
  </p>
</div>
    </div>
  );
}

export default RegistrationPage;