import React, { Component, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ForgotPasword.css";
import { BASE_URL } from "../../../assets/ApiUrls";
import { UserContext } from '../../../context/userContext';

function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state: userData } = location;
  const [pass, setPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
 const {setUser} = useContext(UserContext);
 const [otpTimer, setOtpTimer] = useState(0);
 useEffect(() => {
    if (otpTimer > 0) {
      const timer = setTimeout(() => {
        setOtpTimer(otpTimer - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [otpTimer]);

  const handleResendOTP = () => {
    handleSendOTP()
    console.log('otp sent')
    setOtpTimer(15); // Set timer to 15 seconds
    // Add code here to send OTP
  };

  const handleSendOTP = async (event) => {

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    };

    try {
      const response = await fetch(`${BASE_URL}users/resendOTP?email=${email}`, requestOptions);
      const data = await response.json();
      setResponse(data);
      console.log("Response -- - " , data)

      if (data.status === 1) {
        alert(data.message);
      }
      else{
        alert(data.message);
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoading(false); 
    }
  };

  const handleSubmit = async (event) => {

    console.log("firstname -- " , userData , '== ' , pass)
    if (pass.trim() === "" || confPass.trim() === "") {
      alert("All Fields are required!");
      return; // Exit the function early
    }
    if (pass.trim() !==  confPass.trim()) {
      alert("Password and Confirm Password should be same!");
      return; // Exit the function early
    }
    event.preventDefault();

    setLoading(true);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      // body: JSON.stringify({
      //   firstName: userData.firstname,
      //   lastName: userData.lastname,
      //   email: userData.email,
      //   phone: userData.phone,
      //   password: pass,
      // })
    };

    try {
      const response = await fetch(`${BASE_URL}users/resetPassword?email=${email}&otp=${otp}&newPass=${pass}`, requestOptions);
      const data = await response.json();
      setResponse(data);
      console.log("Response -- - " , data)

      if (data.status === 1) {
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem("username", data.user_name);
        setUser({user_id: data.user_id, username: data.user_name})
      }
      else{
        alert(data.message);
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoading(false); 
    }
  };

  return (
    <div className="registration-container1">
      <div className="headingStyle1">
        <h1 className="admin1">Forgot</h1>
        <h1 className="reg1">Password</h1>
      </div>
      <p className="p1">Create a Strong Password only you can access </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <label htmlFor="field1">Enter New Password*</label> */}
          
          <div className="pass-input-group1">
          <label htmlFor="field2">Enter your Email*</label>
            <input
              type="email"
              id="mail"
              placeholder="youmail@company.com"
              name="email"
              value={email} onChange={(e) => setEmail(e.target.value)}
            />
            <p style={{marginLeft: '20px' , marginTop: '1px' , fontSize: '15px' , fontWeight: 300 , color: '#6D6D6D'}}>{otpTimer === 0 ? (
        <button className="sendOTP"  style={{left: '40%' , position: 'absolute' , bottom: '58%'}}  onClick={handleResendOTP}>
          Send OTP
        </button>
      ) : (
        <p className="sendOTP" style={{left: '35%' , position: 'absolute'}}>
          Resend OTP in {otpTimer} sec
        </p>
      )} </p>
          </div>
          <div className="pass-input-group21" style={{marginBottom: '-50px'}}>
          <label htmlFor="field2">Enter OTP</label>
            <div>
            <input
              type="password"
              id="otp"
              placeholder="6 digit OTP"
              name="otp"
              value={otp} onChange={(e) => setOTP(e.target.value)}
            />
            </div>
          </div>
          <div className="pass-input-group1pass" style={{top: '-100px'}}>
          <label htmlFor="field2">Enter New Password*</label>
            <input
              type="password"
              id="firstName"
              placeholder="************"
              name="firstName"
              value={pass} onChange={(e) => setPass(e.target.value)}
            />
            <p style={{marginLeft: '20px' , marginTop: '1px' , fontSize: '15px' , fontWeight: 300 , color: '#6D6D6D'}}>Use atleast 1 special and 1 numeric character </p>
          </div>
          <div className="pass-input-group21">
          <label htmlFor="field2">Confirm New Password*</label>
            <div>
            <input
              type="password"
              id="lastName"
              placeholder="Password (min. 8 character)"
              name="lastName"
              value={confPass} onChange={(e) => setConfPass(e.target.value)}
            />
            </div>
          </div>
        </div>
        <button style={{marginTop: '-10px'}}  type="submit" onClick={handleSubmit} disabled={loading}>
        {loading ? "Loading..." : "CONFIRM"}
      </button>
    </div>
  );
}

export default ForgotPassword;
