import React, { useContext } from "react";
import "./Drawer.css";
import Xmark from "../../assets/xmark.png";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";

export default function Drawer() {
  const { setDrawer } = useContext(UserContext);
  const navigate = useNavigate();
  return (<div className="drawer-main">
    <div onClick={() => setDrawer(false)} className="cross-wrapper">
      <img src={Xmark} className="cross" />
    </div>
    <div className="drawer-options">
      <div
        onClick={() => {
          navigate("/homePage");
          setDrawer(false);
        }}
        className="drawer-option-wrapper"
      >
        <span>Home</span>
      </div>
      <div
        onClick={() => {
          navigate("/PaymentScreen");
          setDrawer(false);
        }}
        className="drawer-option-wrapper"
      >
        <span>Payments</span>
      </div>
      <div
        onClick={() => {
          navigate("/BrokerScreen");
          setDrawer(false);
        }}
        className="drawer-option-wrapper"
      >
        <span>Brokers</span>
      </div>
      <div
        onClick={() => {
          navigate("/LeadScreen");
          setDrawer(false);
        }}
        className="drawer-option-wrapper"
      >
        <span>Leads</span>
      </div>
      <div
        onClick={() => {
          navigate("/ProfileScreen");
          setDrawer(false);
        }}
        className="drawer-option-wrapper"
      >
        <span>Profile</span>
      </div>
      <div
        onClick={() => {
          navigate("/");
          setDrawer(false);
        }}
        className="drawer-option-wrapper"
      >
        <span>Logout</span>
      </div>
    </div>
  </div>
  );
}
