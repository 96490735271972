import React, { Component, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SetupPassword.css";
import { BASE_URL } from "../../../assets/ApiUrls";
import { UserContext } from '../../../context/userContext';

function SetupPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state: userData } = location;
  const [pass, setPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
 const {setUser} = useContext(UserContext);

  const handleSubmit = async (event) => {

    console.log("firstname -- " , userData , '== ' , pass)
    if (pass.trim() === "" || confPass.trim() === "") {
      alert("All Fields are required!");
      return; // Exit the function early
    }
    if (pass.trim() !==  confPass.trim()) {
      alert("Password and Confirm Password should be same!");
      return; // Exit the function early
    }
    event.preventDefault();

    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        firstName: userData.firstname,
        lastName: userData.lastname,
        email: userData.email,
        phone: userData.phone,
        password: pass,
      })
    };

    try {
      const response = await fetch(`${BASE_URL}users/register`, requestOptions);
      const data = await response.json();
      setResponse(data);
      console.log("Response -- - " , data)

      if (data.status === 1) {
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem("username", data.username);
        setUser({user_id: data.user_id, username: data.username})
      }
      else{
        alert(data.message);
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoading(false); 
    }
  };

  return (
    <div className="registration-container">
      <div className="headingStyle">
        <h1 className="admin">Setup</h1>
        <h1 className="reg">Password</h1>
      </div>
      <p className="p">Create a Strong Password only you can access </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <label htmlFor="field1">Enter New Password*</label> */}
          <div className="pass-input-group">
          <label htmlFor="field2">Enter New Password*</label>
            <input
              type="password"
              id="firstName"
              placeholder="************"
              name="firstName"
              value={pass} onChange={(e) => setPass(e.target.value)}
            />
            <p style={{marginLeft: '20px' , marginTop: '1px' , fontSize: '15px' , fontWeight: 300 , color: '#6D6D6D'}}>Use atleast 1 special and 1 numeric character </p>
          </div>
          <div className="pass-input-group2">
          <label htmlFor="field2">Confirm New Password*</label>
            <div>
            <input
              type="password"
              id="lastName"
              placeholder="Password (min. 8 character)"
              name="lastName"
              value={confPass} onChange={(e) => setConfPass(e.target.value)}
            />
            </div>
          </div>
        </div>
        <button type="submit" onClick={handleSubmit} disabled={loading}>
        {loading ? "Loading..." : "CONFIRM"}
      </button>
      <p>
       Back to ? 
    <a href=" /Register" className="link">Register Page</a> 
  </p>
    </div>
  );
}

export default SetupPassword;
