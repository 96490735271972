import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LogoImage from '../../assets/OptimaLeads.png';
import Bars from "../../assets/bars.png";
import "./NavigationBar.css"
import { UserContext } from '../../context/userContext';
function NavigationBar({tab}) {
  const [selectedTab , setSelectedTab ] = useState(0);
  const {setDrawer , logout} = useContext(UserContext);
  const navigate = useNavigate();
  const {setUser} = useContext(UserContext);

  const handleSubmit =  () => {
    logout()
    navigate('/Login')
    
  }


  return (
    <AppBar position="static"  elevation={5} >
      <Toolbar style={{ justifyContent: 'space-between'  , backgroundColor: '#F8F8F8', padding: '30px'  }}>
      <Link to="/">
          <img src={LogoImage} alt="Logo" style={{ position: 'absolute' ,  height: '80px', marginRight: '150px' , top: '27px' , left: '-55px' }} />
        </Link>
        <div className='nav-link-cont'>
          <Typography variant="h6" component={Link} to="/homePage" style={{ fontWeight: '500' , fontSize: '18px' , margin: '0 20px', textDecoration: 'none',  color: tab == 0?  '#D68B43' : '#626262' , marginLeft: '160px' , backgroundColor: tab == 0 ?  '#FFFFFF' : '#F8F8F8', paddingInline: '20px' , paddingBlock: '10px' , borderRadius: '50px'}}>
            Home
          </Typography>
          <Typography  variant="h6" component={Link} to="/PaymentScreen" style={{fontSize: '18px' , margin: '0 15px', textDecoration: 'none', color:tab == 1?  '#D68B43' :  '#626262' ,backgroundColor: tab == 1 ?  '#FFFFFF' : '#F8F8F8',paddingInline: '20px' , paddingBlock: '10px' , borderRadius: '50px'}}>
            Payments
          </Typography>
          <Typography  variant="h6" component={Link} to="/BrokerScreen" style={{fontSize: '18px' , margin: '0 15px', textDecoration: 'none', color: tab == 2?  '#D68B43' :  '#626262' ,backgroundColor: tab == 2 ?  '#FFFFFF' : '#F8F8F8', paddingInline: '20px' , paddingBlock: '10px' , borderRadius: '50px'}}>
            Brokers
          </Typography>
          <Typography  variant="h6" component={Link} to="/LeadScreen" style={{fontSize: '18px' , margin: '0 15px', textDecoration: 'none', color:tab == 3?  '#D68B43' :  '#626262' , backgroundColor: tab == 3 ?  '#FFFFFF' : '#F8F8F8', paddingInline: '20px' , paddingBlock: '10px' , borderRadius: '50px'}}>
            Leads
          </Typography>
          <Typography  variant="h6" component={Link} to="/ProfileScreen" style={{fontSize: '18px' , margin: '0 15px', textDecoration: 'none', color:tab == 4?  '#D68B43' :  '#626262' , backgroundColor: tab == 4 ?  '#FFFFFF' : '#F8F8F8', paddingInline: '20px' , paddingBlock: '10px' , borderRadius: '50px'}}>
            Profile
          </Typography>
        </div>
        <IconButton sx={{
          paddingInline: '15px',
        backgroundColor: '#F0F0F0',
        borderRadius: '8px', // Adjust the border radius as needed
        '&:hover': {
          backgroundColor: '#D0D0D0', // Change the background color on hover if desired
        },
      }} color="inherit">
         
          <Typography className='logout-button' onClick={handleSubmit} to="/" variant="h6"  style={{ marginRight: '5px' , color: 'black'  }}>Logout</Typography>
          <ExitToAppIcon style={{color:'black'}}/>
          <img onClick={()=>setDrawer(true)} src={Bars} className='navbar-bars'/>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
