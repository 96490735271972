import React, { useEffect, useRef, useState } from 'react';
import '../OtpPage/OtpPage.css'; // Import the CSS file for styling
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../assets/ApiUrls';

function OtpPage() {

  const navigate = useNavigate();
  const location = useLocation();
  const { state: userData } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [otpTimer, setOtpTimer] = useState(0);
  const [loading, setLoading] = useState(false);

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  
    // Function to focus on the next input field
    const focusNextInput = (currentInput, nextInput) => {
      if (currentInput.value && currentInput.value.length === 1) {
        nextInput.current.focus();
      }
    };

    useEffect(() => {
      if (otpTimer > 0) {
        const timer = setTimeout(() => {
          setOtpTimer(otpTimer - 1);
        }, 1000);
        return () => clearTimeout(timer);
      }
    }, [otpTimer]);
  

    const handleResendOTP = () => {
      handleSendOTP()
      console.log('otp sent')
      setOtpTimer(15); // Set timer to 15 seconds
      // Add code here to send OTP
    };

    const handleSendOTP = async (event) => {

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      };
  
      try {
        const response = await fetch(`${BASE_URL}users/resendOTP?email=${userData.email}`, requestOptions);
        const data = await response.json();
        console.log("Response -- - " , data)
  
        if (data.status === 1) {
          alert(data.message);
        }
        else{
          alert(data.message);
        }
      } catch (error) {
        console.error(error);
      }finally {
        setLoading(false); 
      }
    };

    const verifyOTP = async () => {
      
      console.log("Called --- ")
      try {
        if (!input1Ref.current.value ||
          !input2Ref.current.value ||
          !input3Ref.current.value ||
          !input4Ref.current.value ||
          !input5Ref.current.value ||
          !input6Ref.current.value) {
        alert("Please enter the OTP");
        return;
      }

      const otp = `${input1Ref.current.value}${input2Ref.current.value}${input3Ref.current.value}${input4Ref.current.value}${input5Ref.current.value}${input6Ref.current.value}`;
      console.log("OTP: ", otp);

        setIsLoading(true)
        const response = await fetch(`${BASE_URL}users/validateOTP?email=${userData.email}&otp=${otp}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (!response.ok) {
          console.log("Inside not ok")
          throw new Error('Failed to Validate OTP');
        }
        if(data.status){
          navigate("/setupPassword", { state: userData } );
        }
        else{
          alert("Enter Valid OTP")
        }
      } catch (error) {
        console.error(error);
      }
      finally{
        setIsLoading(false)
      }
    };
    
  return (
    <div className="registration-container">
        <div className="headingStyle">
      <h1 className="admin">Verify</h1>
      <h1 className='reg'>Credentials</h1>
      </div>
      <p className='otp-p'>Enter six digit code sent to your registered Email ID</p>
      <div className="otp-container">
      <input
        type="number"
        maxLength={1}
        ref={input1Ref}
        onChange={() => focusNextInput(input1Ref.current, input2Ref)}
      />
      <input
        type="number"
        maxLength={1}
        ref={input2Ref}
        onChange={() => focusNextInput(input2Ref.current, input3Ref)}
      />
      <input
        type="number"
        maxLength={1}
        ref={input3Ref}
        onChange={() => focusNextInput(input3Ref.current, input4Ref)}
      />
      <input
        type="number"
        maxLength={1}
        ref={input4Ref}
        onChange={() => focusNextInput(input4Ref.current, input5Ref)}
      />
      <input
        type="number"
        maxLength={1}
        ref={input5Ref}
        onChange={() => focusNextInput(input5Ref.current, input6Ref)}
      />
      <input
        type="number"
        maxLength={1}
        ref={input6Ref}
        onChange={verifyOTP}
        disabled={isLoading}
      />
    </div>
    <button style={{cursor: "pointer"}}  type="submit" onClick={verifyOTP}disabled={isLoading}>{isLoading ? "Loading.." : "Confirm"}</button>
      <div className='belowButton'>
  <p>{otpTimer === 0 ? (
        <p className="sendOTPStyle"  style={{left: '0%' , position: 'absolute' , bottom: '35%' , cursor: 'pointer'}}  onClick={handleResendOTP}>
          Resend OTP
        </p>
      ) : (
        <p className="sendOTPStyle" style={{left: '0%',  bottom: '35%' , position: 'absolute'}}>
          Resend OTP in {otpTimer} sec
        </p>
      )}
  </p>
  
</div>
    </div>
  );
}

export default OtpPage;