import React, { useState, useContext, useEffect } from "react";
import NavigationBar from "../../Components/NavigattionBar/NavigationBar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import MovingIcon from "@mui/icons-material/Moving";
import { faker } from "@faker-js/faker";
import {useTable} from "react-table";
import './Home.css'
import DatePicker from "react-datepicker";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import 'react-datepicker/dist/react-datepicker.css';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddLeadsModal from "../../Components/AddLeadsModal/AddLeadsModal";
import { UserContext } from "../../context/userContext";
import Drawer from "../../Components/drawer/Drawer";
import Footer from '../../Components/Footer/Footer'
import { BASE_URL } from "../../assets/ApiUrls";
import useDataTable from "../../Components/useTable";
import { CircularProgress } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const dataTable = [
  {
    id: 1,
    brokerName: 'Samuel Jeff',
    email: 'samuel@gmail.com',
    location: 'Canada, USA, UK',
    spent: '62,000',
    contactNumber: '9635760241',
    date: '12th Mar, 2024'
  },
];

const columns = [
{
  Header: "BROKER NAME",
  accessor: "brokerName"
},
{
  Header: "EMAIL",
  accessor: "brokerEmail"
},
{
  Header: "LOCATION",
  accessor: "location"
},
{
  Header: "SPENT",
  accessor: "spent"
},
{
  Header: "CONTACT NO",
  accessor: "brokerPhone",
  Cell: (
    { row } // Render custom cell
  ) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "4px" }}>+1</div>
      {row.original.brokerPhone} {/* Render customer name */}
    </div>
  ),
},
{
  Header:<AccessTimeIcon/>,
  accessor: "createdAt",
  Cell: ({ value }) => new Date(value).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })
  
}

]

const leadsColumns = [
  {
    Header: "Owner Name",
    accessor: "ownerName"
  },
  {
    Header: "Email",
    accessor: "ownerEmail"
  },
  {
    Header: "Monthly Revenue",
    accessor: "monthlyRevenue",
    Cell: ({ value }) => `$ ${value}`
  },
  {
    Header: "Industry",
    accessor: "industry"
  },
  {
    Header: "Location",
    accessor: "location"
  },
  {
    Header : "SIN no",
    accessor: "SINno"
  },
  {
    Header : "EIN no",
    accessor: "EINno"
  },
  {
    Header:<AccessTimeIcon/>,
    accessor: "createdAt",
    Cell: ({ value }) => new Date(value).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    })
  }
  ]
  


const  Home = () => {
  const {user, drawer } = useContext(UserContext);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [username , setUsername] = useState("");
  const [userId, setUserId] = useState(null);
  const [brokerDate , setBrokerData] = useState([])
  const [leadsData , setLeadsData] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [totalAmount  , setTotalAmount] = useState(0);
  
  const [data, setData] = useState({
    labels: [],
    datasets: [{
      label: 'Payments',
      data: [],
      backgroundColor: '#D68B43',
      borderColor: 'rgba(53, 162, 235, 1)',
      borderWidth: 1
    }]
  });
  const [leadDate, setLeadDate] = useState({
    labels: [],
    datasets: [{
      label: 'Leads',
      data: [],
      backgroundColor: '#D68B43',
      borderColor: 'rgba(53, 162, 235, 1)',
      borderWidth: 10
    }]
  });

  

  
  const {
    getTableProps: getBrokersTableProps,
    getTableBodyProps: getBrokersTableBodyProps,
    headerGroups: brokersHeaderGroups,
    rows: brokersRows,
    prepareRow: prepareBrokersRow,
  } = useDataTable(brokerDate, columns);

  const {
    getTableProps: getLeadsTableProps,
    getTableBodyProps: getLeadsTableBodyProps,
    headerGroups: leadsHeaderGroups,
    rows: leadsRows,
    prepareRow: prepareLeadsRow,
  } = useDataTable(leadsData, leadsColumns);


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  useEffect(() => {
    console.log("UseEffect Called -- ")

    const storedName = localStorage.getItem("username")
    console.log("storedUserName ---- " , storedName)
    if(storedName) {
      setUsername(storedName);
    }
    getPaymentData(new Date());
    fetchLeads()
    fetchBrokers()
    fetchTotalAmount();
    getLeadsData(new Date())

  }, []);
  

  const fetchTotalAmount = async () => {
    const storedUserId = localStorage.getItem("user_id");
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}payments/sumAmount?user_id=${storedUserId}`); // Replace with your backend API endpoint
      const data = await response.json();
      if (data.status === 1) {
        setTotalAmount(data.totalAmount);
      } else {
        console.error('Error:', data.message);
      }
    } catch (error) {
      console.error('Error fetching total amount:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadsData = async (date) => {
    setLeadDate({
      labels: [],
      datasets: [{
        label: 'Leads',
        fill: true,
        lineTension: 0.9,
        data: 0,
        borderColor: "#D68B43",
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }]
    });
    console.log('Get number of Leads  -------------------------------- ')
    try {
      const storedUserId = localStorage.getItem("user_id");
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 as month is 0-indexed
      const year = date.getFullYear().toString();
      const formattedDate = `${month}/${year}`;
      console.log("Formatted Lead Date:", formattedDate);
  
      const response = await fetch(`${BASE_URL}payments/getLeadsAccordingToMonth?userId=${storedUserId}&date=${formattedDate}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch Lead Data: ${response.status} ${response.statusText}`);
      }
      const result = await response.json();
      console.log("Fetched Lead Data  -----:", result);
  
      // Check if 'status' property exists and is 1
      if (result.status === 1) {
        // Process the data here
        const formattedData = result.data.map(item => ({
          label: convertDateFormat(item.date), // Convert date format
          amount: item.numberOfPayment
        }));
  
        // Extract labels and amounts from formattedData
        const labels = formattedData.map(data => data.label);
        const amounts = formattedData.map(data => data.amount);
  
        // Update the chart data
        setLeadDate({
          labels: labels, // Use an array with single label
          datasets: [{
            label: 'Leads',
            fill: false,
            lineTension: 0.9,
            data: amounts, // Use an array with single amount
            borderColor: "#D68B43",
            backgroundColor: '#f2dcc6',
          }]
        });
        console.log('Status is 1:', result.data);
      } else {
        console.error("Invalid response format:", result.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const getPaymentData = async (date) => {
    setData({
      labels: [],
      datasets: [{
        label: 'Payments',
        fill: false,
        lineTension: 0.9,
        data: 0,
        borderColor: "#D68B43",
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }]
    });
    console.log('Get Payment Data -- ')
    try {
      const storedUserId = localStorage.getItem("user_id");
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 as month is 0-indexed
      const year = date.getFullYear().toString();
      const formattedDate = `${month}/${year}`;
      console.log("Formatted Date:", formattedDate);
  
      const response = await fetch(`${BASE_URL}payments/getPaymentAccordingToMonth?userId=${storedUserId}&date=${formattedDate}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch Data: ${response.status} ${response.statusText}`);
      }
      const result = await response.json();
      console.log("Fetched Data:", result);
  
      // Check if 'status' property exists and is 1
      if (result.status === 1) {
        // Process the data here
        // const formattedData = result.data.payments.map(item => ({
        //   label: convertDateFormat(item.date), // Convert date format
        //   amount: item.amount
        // }));

        // const labels = formattedData.map(data => data.label);
        // const amounts = formattedData.map(data => data.amount);

        const data = result.data[0]; // Assuming only one data entry
      const labels = data.payments.map(payment => (convertDateFormat(payment.date)));
      const amounts = data.payments.map(payment => payment.amount);

      // Update the chart data
      setData({
        labels: labels,
        datasets: [{
          label: 'Payments',
          fill: false,
          lineTension: 0.9,
          data: amounts,
          borderColor: "#D68B43",
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }]
      });
        console.log('Status is 1:', result.data);
      } else {
        console.error("Invalid response format:", result.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const fetchBrokers = async () => {
    const storedUserId = localStorage.getItem("user_id");
    try {
      const response = await fetch(`${BASE_URL}brokers/getBrokers?user_id=${storedUserId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch brokers: ${response.status} ${response.statusText}`);
      }
      const result = await response.json(); 
      console.log("Fetched brokers:", result.status); 
      if (result.status == 1) {
        setBrokerData(result.data); 
      } else {
        console.error("Invalid response format:", result);
      }
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }finally{
      setIsLoading(false)
    }
  };
  
  const fetchLeads = async () => {
    setIsLoading(true)
    const storedUserId = localStorage.getItem("user_id");
    try {
      const response = await fetch(`${BASE_URL}leads/getLeads?user_id=${storedUserId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch Leads");
      }
      const result = await response.json();
      console.log("Leads -- " , result.status)
      // setBrokerData(response?.data)
      if (result.status == 1 ) {
        setLeadsData(result.data); // Append new data to existing data
      } else {
        console.error("Invalid response format:", result);
      }
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false)
    }
  };

  const generateXAxisLabels = () => {
    const daysInMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).getDate();
    const labels = [];
    for (let i = 0; i <= daysInMonth; i+=5) {
      labels.push(i);
    }
    labels.push(daysInMonth)
    return labels;
  };

  const generateData = () => {
    const daysInMonth = new Date(new Date().getFullYear(), selectedMonth, 0).getDate();
    const data = [];
    for (let i = 0; i <= daysInMonth; i+=5) {

      data.push(Math.floor(Math.random() * 100));
    }
    return data;
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false, 
        },
        title: {
          display: true,
        },
        ticks: {
          display: true,
        },
      },
      y: {
        ticks: {
          display: true, 
        },
      }
    },
  };

  // const data = {
  //   labels : generateXAxisLabels(),
  //   datasets: datas
  //   // datasets: [
  //   //   {
  //   //     label: 'Data',
  //   //     fill: false,
  //   //     lineTension: 0.9,
  //   //     label: "Dataset",
  //   //     // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //   //     data: [0.2 , 0.5 , 0.8 , 0.9 , 1 , 0.6 , 0,9],
  //   //     borderColor: "#D68B43",
  //   //     // backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //   //   },
  //   // ],
  // };
  const customColorFunction = (context) => {
    const value = context.dataset.data[0]; // Assuming only one bar
    let gradientColors = [];
    
    if (value < 1) {
      gradientColors = ['#FF6347', '#FFA500', '#32CD32']; // Red, Orange, Green
    } else if (value < 0.6) {
      gradientColors = ['#FF6347', '#FFA500']; // Red, Orange
    } else {
      gradientColors = ['#FFA500', '#32CD32']; // Orange, Green
    }}


  const barData = {
    labels: generateXAxisLabels(),
    datasets: [
      {
        label: 'Data',
        data: [0.2 , 0.5 , 0.8 , 0.9 , 1 , 0.6 , 0,9],
        backgroundColor: '#f2dcc6',
        borderWidth: 1,
      },
    ],
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  


  const CustomInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: 'none', outline: 'none', borderBottom: '-1px solid transparent', color: '#11263c' , marginInline: '6px' , fontWeight: '500'  , fontSize: '14px' }} // Remove underline
    />
  );

  function convertDateFormat(dateString) {
    const parts = dateString.split('/');
    // Rearrange the parts to format "mm/dd/yyyy"
    return `${parts[1]}/${parts[0]}/${parts[2]}`;
  }

  return (
    <div>
    { drawer ? <Drawer /> : 
    <div>
      <NavigationBar tab={0} />
      {isLoading ? ( // Conditional rendering based on loading state
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress /> {/* Display CircularProgress component while loading */}
        </div>
      ) : (
      <div style={{ paddingInline: "5%" , marginTop: '30px' }}>
        <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between'  , alignItems: 'center'}}>
          <div>
        <h2 style={{fontSize: '26px' , fontWeight: '500'}}>Hi {username}</h2>
        <p style={{ marginTop: "-20px", color: "grey" , fontSize: '26px' , fontSize: '17px'  }}>
          Here’s what happening with your leads
        </p>
        </div>
        <div onClick={openModal} style={{ display: 'flex', height: '20px', left: '50%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' }}>
      <PlaylistAddIcon style={{ marginInline: '5px', height: '25px' , color: '#21272A' }} />
      <div>Add Leads</div>
         </div>
        </div>
        <AddLeadsModal isOpen={isModalOpen} onClose={closeModal} onSubmit={fetchLeads} />

        <div className="date-calender-wrapper">
        <div className="date-calender-picker">
                  <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' }}>
                    <h3 style={{fontWeight: '500'}}>Payments</h3>
      <div className="date-calender">
      <DatePicker
            className="date-picker"
            selected={selectedMonth}
            onChange={(date) => {setSelectedMonth(date); getPaymentData(date) ; getLeadsData(date)}}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            customInput={<CustomInput />} // Use customInput to render a custom input component
          />
        <CalendarTodayOutlinedIcon style={{ marginLeft: '3px', color: '#D68B43' , marginBottom:'10px'  , height: '20px'}} />
      </div>
      </div> 
      <Line data={data} options={options} height={'130%' } style={{marginBottom: '-10px'}}/>
      </div>
          <div
            style={{
              justifyContent: "space-between",
              marginInline: "3%",
              paddingRight: "10px",
            }}
          >
            <div
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                paddingLeft: "52px",
                paddingRight: "33px",
                paddingBottom: "36px",
                paddingTop: '30px'
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "10px",
                  color: '#393939'
                }}
              >
                Payment Received
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "26px",
                    marginRight: "20px",
                    color: "#393939",
                    fontWeight: "500",
                    marginTop: "-4%",
                  }}
                >
                  {`$${totalAmount}`}
                </div>
                <div style={{ display: "flex", flexDirection: "row"  , alignItems: 'center'}}>
                  <div
                    style={{
                      color: "#D68B43",
                      fontSize: "14px",
                      flexDirection: "row",
                      fontWeight: '500'
                    }}
                  >
                    00.00
                  </div>
                  <NorthEastIcon style={{ color: "#D68B43", height: "13px" }} />
                </div>
              </div>
            </div>
            <div
              style={{
                padding: '20px',
                background: "#fff",
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                marginTop: "30px",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  color: '#1C1C1C',
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                Brokers Count
              </div>
              <div style={{ alignItems: "center" }}>
              <div style={{ fontSize: '30px', fontWeight: '700', color: '#393939', marginTop: '0%' }}>{brokerDate.length}</div>

                <div style={{ display: "flex", flexDirection: "row" , justifyContent: 'center' , alignItems: 'center' , marginTop: '9px' }}>
                  <div
                    style={{
                      color: "#D68B43",
                      fontSize: "16px",
                      flexDirection: "row",
                      fontWeight: '400',
                      marginRight: '10px'
                    }}
                  >
                    {"+00.00" + "%"}
                  </div>
                  <MovingIcon style={{ color: "#D68B43", height: "43px" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="home-brokers-section">
                   <h3 style={{fontWeight: '500'}}>Lead Sold</h3>
            <Bar options={options} data={leadDate} height={'200%' } />
          </div>
        </div>
        <h2>Recent Brokers</h2>
        <div className="tablesContainer">
            <table className="homeTable" {...getBrokersTableProps()}>
              <thead>
                {brokersHeaderGroups.map((hg) => (
                  <tr {...hg.getHeaderGroupProps()}>
                    {hg.headers.map((header)=>(
                      <th {...header.getHeaderProps()}>
                        {header.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              
            {
              brokerDate.length ==0 ? <div style={{height: '50vh', width: '90%' , paddingTop: '10%' , position: 'absolute' ,  textAlign: 'center' , alignSelf: 'center' , alignItems: 'center' , justifyContent: 'center' , fontSize: '17px'}}>
              <InfoOutlinedIcon style={{color: 'black' , height: '14px'}}></InfoOutlinedIcon> No Data
            </div>:
            <tbody {...getBrokersTableBodyProps()}>
                {brokersRows.map(row=>{
                  prepareBrokersRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell=>(
                        <td {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>}
            </table>
          </div>
        <h2 style={{marginTop: '40px'}}>Leads Added</h2>
        <div className="tablesContainer">
            <table className="homeTable" {...getLeadsTableProps()}>
              <thead>
                {leadsHeaderGroups.map((hg) => (
                  <tr {...hg.getHeaderGroupProps()}>
                    {hg.headers.map((header)=>(
                      <th {...header.getHeaderProps()}>
                        {header.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
                 
            {
              brokerDate.length ==0 ? <div style={{height: '50vh', width: '90%' , paddingTop: '10%' , position: 'absolute' ,  textAlign: 'center' , alignSelf: 'center' , alignItems: 'center' , justifyContent: 'center' , fontSize: '17px'}}>
              <InfoOutlinedIcon style={{color: 'black' , height: '14px'}}></InfoOutlinedIcon> No Data
            </div>:
              <tbody {...getLeadsTableBodyProps()}>
                {leadsRows.map(row=>{
                  prepareLeadsRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell=>(
                        <td {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            }
            </table>
          </div>
      </div>
      )}</div>
   }
   <div style={{marginTop: '100px'}}>
      <Footer style={{ marginTop: '100px' }} />
      </div>
   </div>
  );
}
export default Home;