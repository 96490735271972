import {
    setUSER,
    setDRAWER
} from './types';

const setUser = (user, state) => {
    return {
        ...state,
        user
    };
};

const setDrawer = (drawer, state) => {
    return {
        ...state,
        drawer
    };
};

export const userReducer = (state, action) => {
    switch (action.type) {
        case setUSER:
            return setUser(action.payload, state);
        case setDRAWER:
            return setDrawer(action.payload, state);
        default:
            return state;
    }
};