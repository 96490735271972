import React, { useCallback, useContext, useEffect, useState } from "react";
import NavigationBar from "../../Components/NavigattionBar/NavigationBar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { useTable } from "react-table";
import "./PaymentScreen.css";
import Footer from "../../Components/Footer/Footer";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddLeadsModal from "../../Components/AddLeadsModal/AddLeadsModal";
import Drawer from "../../Components/drawer/Drawer";
import { UserContext } from "../../context/userContext";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { BASE_URL } from "../../assets/ApiUrls";
import CreatePayment from "../../Components/CreatePayment/CreatePayment";
import { CircularProgress } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const dataTable = [
  {
    id: 1,
    dateCreated: "12th Mar, 2024",
    updatedOn: "12th Mar, 2024",
    CustomerName: "Anette Black",
    EmailId: "samuel@gmail.com",
    contactNumber: "9635760241",
    amount: "2000",
    status: "Paid",
    image:
      "https://i.pinimg.com/736x/d5/59/bd/d559bd5ffda47d35f8d5ce8de8d6f325.jpg",
  },
]

const columns = [
  {
    Header: "Date created",
    accessor: "createdAt",
    Cell: ({ value }) => new Date(value).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    })
  },
  {
    Header: "Updated on",
    accessor: "updatedAt",
    Cell: ({ value }) => new Date(value).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    })
  },
  {
    Header: "Customer Name",
    accessor: "brokerName", // Change accessor name
    // Cell: (
    //   { row } // Render custom cell
    // ) => (
    //   <div style={{ display: "flex", alignItems: "center" }}>
    //     <img
    //       src={row.original.image}
    //       alt="Customer"
    //       style={{
    //         marginRight: "5px",
    //         alignItems: "center",
    //         width: "30px",
    //         height: "30px",
    //         borderRadius: "50%", // Rounded corners
    //         padding: "5px", // Padding
    //       }}
    //     />
    //     {row.original.CustomerName} {/* Render customer name */}
    //   </div>
    // ),
  },
  {
    Header: "Email Id",
    accessor: "brokerEmail",
  },
  {
    Header: "Phone Number",
    accessor: "brokerPhone",
    Cell: (
      { row } // Render custom cell
    ) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "4px" }}>+1</div>
        {row.original.brokerPhone} {/* Render customer name */}
      </div>
    ),
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: (
      { row } // Render custom cell
    ) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "4px" }}>$</div>
        {row.original.amount} {/* Render customer name */}
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) => (
      <div style={{display: 'flex' , flexDirection: 'row' ,  justifyContent: 'space-between'  , alignItems: 'center' , cursor: 'pointer'}}>
      <div style={{ display: "flex", alignItems: "center"  , backgroundColor: row.original.status === "Pending" ? "#FEF3F2" : "#ECFDF3", borderRadius: '10px', padding: '2px'}}>
        
          <Brightness1Icon style={{ marginRight: "5px", color:row.original.status === "Pending" ?  "#B3332A" : '#027A48' , height: '10px' }}  />
        {/* )  */}
        <div
          style={{
            // background: row.original.status === "Pending" ? "#FEF3F2" : "#ECFDF3",
            padding: "5px",
            borderRadius: "5px",
            color:  row.original.status === "Pending" ? '#B3332A' : '#027A48'
          }}
        >
          {row.original.status}
        </div>
        
      </div>

      {
       row.original.status === "Pending" ?  
       <>
       <div
       style={{
        border: '1px  solid  grey',
         paddingInline: "6px",
         borderRadius: "5px",
         color:  'black',
         marginRight: '13px'
       }}
     
     >
       Paid
     </div> <DriveFileRenameOutlineOutlinedIcon style={{marginLeft: '-17px'}}/> </> : null
      }
      </div>
    ),
  },
];

const PaymentScreen = () => {
  const {drawer} = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ hoveredRow , setHoveredRow] = useState(null);
  const [paymentData , setPaymentData] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
  useTable({
    columns,
    data: paymentData,
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchPayments();
  } , [])

  const handlePaidButtonClick = async ( userId ,paymentId , amount) => {
    try {
      const response = await fetch(`${BASE_URL}/payments/updateRecord?userId=${userId}&paymentId=${paymentId}&amount=${amount}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        alert("Failed to Add Payment Record!")
        throw new Error('Failed to mark payment as paid');
      }
      // Refresh payments data after marking as paid
      fetchPayments();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPayments = async () => {
    setIsLoading(true)
    const storedUserId = localStorage.getItem("user_id");
    try {
      const response = await fetch(`${BASE_URL}payments/getPayments?user_id=${storedUserId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch Leads");
      }
      const result = await response.json();
      console.log("Leads -- " , result.status)
      // setBrokerData(response?.data)
      if (result.status == 1 ) {
        setPaymentData(result.data); // Append new data to existing data
      } else {
        console.error("Invalid response format:", result);
      }
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false)
    }
  };

  
  const handleRowClick = (row) => {
    console.log("Row clicked:" , row);
    // Add your logic here to handle row click
  };


  return (
    <div>
    { drawer ? <Drawer /> : 
    <div style={{ display: 'flex', flexDirection: 'column',  overflowX: 'auto'  , height: '150vh' , backgroundColor:'#F8F8F8' }}>
      <NavigationBar tab={1} />
      {isLoading ? ( // Conditional rendering based on loading state
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress /> {/* Display CircularProgress component while loading */}
        </div>
      ) : (
      <div style={{ paddingInline: "3%" , paddingBottom: '5vh' , flex: 1,  backgroundColor: '#FFFFFF' , width: '90%' , alignSelf: 'center' , marginTop: '5vh' , borderRadius: '20px' , marginBottom: '4vh', overflowX:"auto"}}>
      <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between'  , alignItems: 'center' , cursor: 'pointer'}}>
        <h2>Payments</h2>
        <div onClick={openModal} style={{ display: 'flex', height: '20px', left: '50%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' }}>
      <PlaylistAddIcon style={{ marginInline: '5px', height: '25px' , color: '#21272A' }} />
      <div>Create Record</div>
      </div>
      </div>
      <CreatePayment isOpen={isModalOpen} onClose={closeModal}  onSubmit={fetchPayments}/>
          <table className="paymentTable" {...getTableProps()}>
            <thead>
              {headerGroups.map((hg) => (
                <tr {...hg.getHeaderGroupProps()}>
                  {hg.headers.map((header) => (
                    <th {...header.getHeaderProps()} style={{ position: 'relative', whiteSpace: 'nowrap', fontWeight: '400' }}>
                      {header.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {
              paymentData.length ==0 ? <div style={{height: '50vh', width: '90%' , paddingTop: '10%' , position: 'absolute' ,  textAlign: 'center' , alignSelf: 'center' , alignItems: 'center' , justifyContent: 'center' , fontSize: '17px'}}>
              <InfoOutlinedIcon style={{color: 'black' , height: '14px'}}></InfoOutlinedIcon> No Data
            </div>:
            <tbody {...getTableBodyProps()}>
              {rows.map((row , index) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()}
                  onClick={() => handleRowClick(row)}
                  onMouseEnter={(e) =>{
                    setHoveredRow(index)
                  }}
                  onMouseLeave={(e) =>{
                    setHoveredRow(null)
                  }}
                  style={{ backgroundColor: hoveredRow == index ? '#f2dcc6' : 'transparent'  }}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell.column.id === "status" && row.original.status === "Pending" ? (
              <div style={{display: 'flex' , flexDirection: 'row' ,  justifyContent: 'space-between'  , alignItems: 'center' , cursor: 'pointer'}}>
              <div style={{ display: "flex", alignItems: "center"  , backgroundColor: row.original.status === "Pending" ? "#FEF3F2" : "#ECFDF3", borderRadius: '10px', padding: '2px'}}>
                
                  <Brightness1Icon style={{ marginRight: "5px", color:row.original.status === "Pending" ?  "#B3332A" : '#027A48' , height: '10px' }}  />
                {/* )  */}
                <div
                  style={{
                    // background: row.original.status === "Pending" ? "#FEF3F2" : "#ECFDF3",
                    padding: "5px",
                    borderRadius: "5px",
                    color:  row.original.status === "Pending" ? '#B3332A' : '#027A48'
                  }}
                >
                  {row.original.status}
                </div>
                
              </div>
        
              {
               row.original.status === "Pending" ?  
               <>
               <div
               style={{
                border: '1px  solid  grey',
                 paddingInline: "6px",
                 borderRadius: "5px",
                 color:  'black',
                 marginRight: '13px'
               }}
               onClick={(e) => {
                e.stopPropagation(); // Prevent row click when clicking on the "Paid" button
                handlePaidButtonClick(
                  row.original.user_id,
                  row.original._id,
                  row.original.amount
                );
              }}
             >
               Paid
             </div> <DriveFileRenameOutlineOutlinedIcon style={{marginLeft: '-17px'}}/> </> : null
              }
              </div>
            ) : (
              // Render other cells as usual
              cell.render("Cell")
            )}
                      
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          }
          </table>
        
      
      </div>)}
      <Footer style={{ marginTop: 'auto' }} />
    </div>
}
</div>
    
  );
};

export default PaymentScreen;
