import React, { useEffect, useContext, useState } from 'react'
import NavigationBar from '../../Components/NavigattionBar/NavigationBar'
import Footer from '../../Components/Footer/Footer'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import './ProfileScreen.css'
import Drawer from '../../Components/drawer/Drawer';
import { UserContext } from '../../context/userContext';
import { CircularProgress } from "@mui/material";
import { BASE_URL } from '../../assets/ApiUrls';

export default function ProfileScreen() {
  const {user, drawer} = useContext(UserContext);
  const [profileData , setProfileData]  = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [editProfile, setEditProfile] = useState(false);
  const [name , setName] = useState();
  const [email , setEmail] = useState();
  const [phone , setPhone] = useState();
  const [activityDetail , setActivityDetails] = useState([]);

  const handleEditProfile = () => {
    setName(profileData?.firstName + " "  + profileData?.lastName );
    setEmail( profileData?.email)
    setPhone( profileData?.phone)
    setEditProfile(!editProfile); // Toggle editProfile state
  };

  const handleNameChange = (e) => {
    if (editProfile) {
      setName(e.target.value);
    }
  };
    useEffect(() => {
        console.log("Welcome to the Profile Screen")
        fetchProfile();
        fetchActivity();
    } , [])

    const addBroker = async (event) => {
      
      const storedUserId = localStorage.getItem("user_id");
      const [firstName, lastName] = name?.split(' ');
      setIsLoading(true)
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          
          firstName: firstName,
          lastName : lastName,
          phone : phone,
          email :  email
        })
      };
  
      try {
        const response = await fetch(`${BASE_URL}users/editProfile?userId=${storedUserId}`, requestOptions);
        const data = await response.json();

        console.log("Response -- - " , data)
        if (data.status === 1) {
          console.log("Response -- - " , data)
          fetchProfile();
          setEditProfile(false)
          alert(data.message)
        }
        else{
          alert(data.message)
        }
      } catch (error) {
        console.error(error);
      }finally {
        setIsLoading(false)
      }
    };

    const getDate = (modDate) => {
      const timestamp = modDate;
const date = new Date(timestamp);

// Get day, month, and year
const day = date.getDate().toString().padStart(2, '0');
const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed, so we add 1
const year = date.getFullYear();

// Format the date as dd/mm/yyyy
const formattedDate = `${day}/${month}/${year}`;

return (formattedDate); // Output: 02/04/2024

    }
    const fetchProfile = async () => {
      setIsLoading(true)
      const storedUserId = localStorage.getItem("user_id");
      try {
        const response = await fetch(`${BASE_URL}users/getProfile?user_id=${storedUserId}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch brokers: ${response.status} ${response.statusText}`);
        }
        const result = await response.json(); // Parse response as JSON
        console.log("Fetched brokers:", result); // Check the structure of result
        
        // Check if 'data' property exists and is an array
        if (result.status == 1) {
          console.log('STatue is 1 --------- ')
          setProfileData(result.data); // Append new data to existing data
        } else {
          console.error("Invalid response format:", result);
        }
      } catch (error) {
        console.error("Error fetching brokers:", error);
      }finally{
        setIsLoading(false)
      }
    };

    const fetchActivity = async () => {
      setIsLoading(true)
      const storedUserId = localStorage.getItem("user_id");
      try {
        const response = await fetch(`${BASE_URL}activity/getActivity?user_id=${storedUserId}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch brokers: ${response.status} ${response.statusText}`);
        }
        const result = await response.json(); // Parse response as JSON
        console.log("Fetched brokers:", result); // Check the structure of result
        
        // Check if 'data' property exists and is an array
        if (result.status == 1) {
          console.log('STatue is 1 --------- ')
          setActivityDetails(result.data)
        } else {
          console.error("Invalid response format:", result);
        }
      } catch (error) {
        console.error("Error fetching brokers:", error);
      }finally{
        setIsLoading(false)
      }
    };

    function convertDateFormat(dateString) {
      const parts = dateString.split('/');
      // Rearrange the parts to format "mm/dd/yyyy"
      return `${parts[1]}/${parts[0]}/${parts[2]}`;
    }

  return (
    <div>
    { drawer ? <Drawer /> : 
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , backgroundColor: '#F8F8F8' }}>
      <NavigationBar tab={4}/>
      {isLoading ? ( // Conditional rendering based on loading state
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress /> {/* Display CircularProgress component while loading */}
        </div>) :
      <div className='profile-wrapper'>
        
        <div className='profile-page'>
            {/* Left Part */}
            <div className='profile-left-cont'>
  <div className='profile-head-cont'>
    <h2 style={{ whiteSpace: 'nowrap' , fontWeight: '500' }}>Your Profile</h2>
    <div style={{ marginLeft: '25px', whiteSpace: 'nowrap' , fontSize: '13px' , color: '#333333' }}>Last Modified:  {convertDateFormat(getDate(profileData.createdAt))}</div>
   
    <div style={{display: 'flex', position: 'absolute', height: '20px', left: editProfile ? '43%' : '48%', paddingInline: '15px', paddingBlock: '5px', alignItems: 'center' }}>
        <div  onClick={handleEditProfile} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , cursor: 'pointer' ,  backgroundColor: editProfile ? 'white': '#f2dcc6' }}>
        {!editProfile ?<ModeOutlinedIcon style={{ marginLeft: '5px', height: '20px' , color: '#21272A' }} />:<></>}
      <div style={{fontSize: '14px' , marginLeft: '5px'}}>{editProfile ? 'Cancel' : 'Edit Profile'}</div>
      
      </div>
      {
        editProfile ? 
        <div onClick={addBroker} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , marginLeft: '10px' ,  cursor: 'pointer' , backgroundColor: '#f2dcc6' }}>
        <ModeOutlinedIcon style={{ marginLeft: '15px', height: '20px' , color: '#21272A' }} />
       <div>Confirm</div>
        
        </div>
        :
    <></>
      }
      </div>
  </div>
  <div className='profileStyle'>
  <img src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg" alt="Your Image" style={{
    width: '100%',

    height: '100%',
    borderRadius: '50%', // To ensure the image is also circular
    border: '2px solid white', // White border around the circular image
  }} />
</div>
<div className="profileInput">
          <label style={{marginBottom: '50px'  , color: '#333333'}}  htmlFor="field2">Full Name</label>
            <div>
            <input
              type="text"
              id="fullname"
              placeholder="Enter your name "
              name="lastName"
              value={!editProfile ? profileData?.firstName+ " " + profileData?.lastName : name}
              readOnly={!editProfile}
              onChange={handleNameChange}  
            />
            </div>
          </div>
          <div className='profile-input-wrapper'>
  <div className="profileInputmini">
    <label style={{ marginBottom: '50px', color: '#333333' }} htmlFor="field1">Email ID</label>
    <div>
      <input
        type="text"
        id="email"
        placeholder="yourmail@company.com"
        value={!editProfile ?  profileData?.email : email}
        readOnly={!editProfile}
        name="email"
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>
  </div>

  <div className="profileInputmini">
    <label style={{ marginBottom: '50px', color: '#333333'  }} htmlFor="field2">Phone No.</label>
    <div>
      <input
        type="number"
        id="phone"
        placeholder="9000000000"
        value={!editProfile ?   profileData?.phone: phone} 
        readOnly={!editProfile}
        name="phone"
        onChange={(e) => setPhone(e.target.value)}
      />
    </div>
  </div>
  
</div>
{/* <div className='profile-input-wrapper'>
  <div className="profileInputmini" >
    <label style={{ marginBottom: '50px', color: '#333333' }} htmlFor="field1">Country</label>
    <div>
      <input
        type="text"
        id="firstName"
        placeholder="India"
        name="firstName"
        value={profileData ? profileData.phone: ''} 
        readOnly={!editProfile}
        
      />
    </div>
  </div>

  <div className="profileInputmini">
    <label style={{ marginBottom: '50px', color: '#333333'  }} htmlFor="field2">Currency</label>
    <div>
      <input
        type="text"
        id="lastName"
        placeholder="INR"
        name="lastName"
      />
    </div>
  </div>
  
</div> */}

</div>


        {/* Right Part */}
        <div className='profile-right-cont'>
            <div style={{display: 'flex' , flexDirection: 'row' , alignItems: 'center'}}>
            <h2 style={{marginRight: '13px' , fontWeight: '500' , color: '#111111'}}>Activity</h2>
            <LaunchOutlinedIcon/>
            </div>
            <div className='right-cont-line'></div>
            <div style={{display: 'flex' , flexDirection: 'row'  , width: '111%'}}>
                <div style={{whiteSpace: 'nowrap' }}>
                    Jan 22 
                </div>
                <div style={{marginLeft: '35%'}}>
                <div style={{fontSize: '18px' , fontWeight: '600' }}>
                    245 Leads to  <span style={{ color: '#D68B43', textDecoration: 'underline' }}>Samuel</span>
                </div>
                <div style={{color: '#667085' ,  fontSize: '14px' , marginTop: '3%'}}>
                Note appears here; Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit arcu aliquet ut dui egestas.
                </div>
                <div style={{ display: 'flex', paddingInline: '7px', paddingBlock: '10px', alignItems: 'center', border: '2px solid #D0D5DD', marginTop: '10%' }}>
  <TextSnippetOutlinedIcon style={{ marginInline: '2px', height: '20px', color: '#D68B43' }} />
  <div style={{ color: '#D68B43'}}>user.csv</div>
</div>

                </div>
            </div>
            <div style={{display: 'flex' , flexDirection: 'row'  , width: '111%', marginTop: '10%'}}>
                <div style={{whiteSpace: 'nowrap' }}>
                    Nov  4
                </div>
                <div style={{marginLeft: '35%'}}>
                <div style={{ fontSize: '18px', fontWeight: '600' , whiteSpace: 'none' }}>
  Payment Received from <span style={{ color: '#D68B43', textDecoration: 'underline' }}>Samuel</span>
</div>
                <div style={{color: '#667085' ,  fontSize: '14px' , marginTop: '3%'}}>
                Note appears here; Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit arcu aliquet ut dui egestas.
                </div>
                <div style={{ display: 'flex', paddingInline: '7px', paddingBlock: '10px', alignItems: 'center', border: '2px solid #D0D5DD', marginTop: '10%' }}>
  <TextSnippetOutlinedIcon style={{ marginInline: '2px', height: '20px', color: '#D68B43' }} />
  <div style={{ color: '#D68B43', flex: 0 }}>invoice.pdf</div>
</div>

                </div>
            </div>
            <div style={{display: 'flex' , flexDirection: 'row'  , width: '111%' , marginTop: '10%'}}>
                <div style={{whiteSpace: 'nowrap' }}>
                    Nov 3
                </div>
                <div style={{marginLeft: '35%'}}>
                <div style={{fontSize: '20px' , fontWeight: '600' }}>
                     Broker Added
                </div>
        

                </div>
            </div>
        </div>
        </div>
       
      </div>}
    </div>}
    </div>
  )
}
