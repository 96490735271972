import React , { useEffect, useState } from 'react';
import './AddBrokerModal.css'
import CloseIcon from '@mui/icons-material/Close';
import { BASE_URL } from '../../assets/ApiUrls';

const AddBrokerModal = ({ isOpen, onClose  , onSubmit}) => {
  
  const [brokerName , setBrokerName] = useState("");
  const [response, setResponse] = useState("");
  const [brokerEmail , setBrokerEmail] = useState("");
  const [brokerPhone , setBrokerPhone] = useState("");
  const [brokerCompany , setBrokerCompany] = useState("");
  const [location , setLocation] = useState("");
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  

  useEffect(() => {
    console.log("UseEffect Called -- ")
    const storedUserId = localStorage.getItem("user_id");
    console.log("UseEffect Called -- " , storedUserId)

    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  if (!isOpen) return null;


  const addBroker = async (event) => {
    setIsLoading(true)
    console.log("Clicked " , userId)
    if (userId.trim() === "" || brokerName.trim() === "" || brokerEmail.trim() === "" || brokerPhone.trim() === "" || brokerCompany.trim() === "" ||  location.trim() === "") {
      alert("All Fields are required!");
      setIsLoading(false)
      return; // Exit the function early
    }
    event.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        
          user_id: userId,
          brokerName : brokerName,
          brokerEmail : brokerEmail,
          brokerPhone :  brokerPhone,
          companyName : brokerCompany,
          location : location,
      })
    };

    try {
      const response = await fetch(`${BASE_URL}/brokers/create`, requestOptions);
      const data = await response.json();
      setResponse(data);
      console.log("Response -- - " , data)
      if (data.status === 1) {
        console.log("Response -- - " , data)
        onSubmit();
        onClose()
      }
      else{
        alert(data.message)
      }
    } catch (error) {
      console.error(error);
    }finally {
      setIsLoading(false)
    }
  };




  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' , alignItems: 'center'}}>
        <h3>Add Broker</h3>
        <CloseIcon className="close-btn" onClick={onClose} />
        </div>
       
        
        <div>The Lead details can be modified later. You will have to upload a new lead with same Lead ID to override existing database entry.</div>
        <form onSubmit={handleSubmit}>
          {/* <label>
            Name:
            <input type="text" />
          </label> */}
          <div className="rows">
        <div className="input-groups">
          <input style={{width: '400px' , padding: '10px'}}   type="text" id="email" placeholder='Broker name' name="text" value={brokerName} onChange={(e) => setBrokerName(e.target.value)}  />
        </div>
      </div>
      <div className="rows">
        <div className="input-groups">
          <input style={{width: '400px' , padding: '10px'}}   type="email" id="email" placeholder='Broker Email ID' name="email" value={brokerEmail} onChange={(e) => setBrokerEmail(e.target.value)}  />
        </div>
        <div className="input-groups">
          <input style={{width: '400px' , padding: '10px'}} type="tel" id="phone" placeholder='Broker Phone Number' name="phone" value={brokerPhone} onChange={(e) => setBrokerPhone(e.target.value)}  />
        </div>
      </div>
      <div className="rows">
        <div className="input-groups">
          <input style={{width: '400px' , padding: '10px'}}   type="text" id="email" placeholder='Company Name' name="name" value={brokerCompany} onChange={(e) => setBrokerCompany(e.target.value)}  />
        </div>
        <div className="input-groups">
          <input style={{width: '400px' , padding: '10px'}} type="text" id="phone" placeholder='Location' name="name" value={location} onChange={(e) => setLocation(e.target.value)}  />
        </div>
      </div>
      <button style={{cursor: "pointer"}} className='buttons' onClick={addBroker
          } disabled={isLoading} type="submit">{isLoading ? "Loading..." : "Confirm"}</button>
        </form>
      </div>
    </div>
  );
};

export default AddBrokerModal;
