import React, { createContext, useReducer } from 'react';
import {
    setUSER,
    setDRAWER
} from './types';

// Reducers
import { userReducer } from './reducers';
// Global User Context
export const UserContext = createContext();

const initialState = {
    user: null,
    drawer: false
};

// Global User Provider
export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, initialState);

    const setUser = (user) => {
        dispatch({ type: setUSER, payload: user });
    };

    const setDrawer = (drawer) => {
        dispatch({ type: setDRAWER, payload: drawer });
    };

    const logout = () => {
        // Clear user data from local storage
        localStorage.removeItem("user_id");
        localStorage.removeItem("username");
        // Clear user context
        setUser(null);
    };

    return (
        <UserContext.Provider
            value={{
                user: state.user,
                drawer: state.drawer,
                setUser,
                setDrawer,
                logout
            }}>
            {children}
        </ UserContext.Provider>
    );
};