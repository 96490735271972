import React, { Component, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./LoginPage.css";
import { BASE_URL } from "../../../assets/ApiUrls";
import { UserContext } from "../../../context/userContext";

function Login() {
  const {user, setUser} = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { state: userData } = location;
  const [pass, setPass] = useState("");
  const [emailMob , setEmailMob] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("State -- " , userData)

  const handleSubmit = async (event) => {
    console.log("firstname -- " , userData , '== ' , pass)
    if (pass.trim() === "" || emailMob.trim() === "") {
      alert("All Fields are required!");
      return; // Exit the function early
    }
    event.preventDefault();

    setLoading(true)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        emailMob: emailMob,
        password: pass,
      })
    };

    try {
      const response = await fetch(`${BASE_URL}users/login`, requestOptions);
      const data = await response.json();
      setResponse(data);
      console.log("Response -- - " , data)
      if (data.status === 1) {
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem("username" , data.user_name);
        console.log("Response -- - " , data)
        setUser({user_id:data.user_id, username:data.user_name})
      }
      else{
        alert(data.message)
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoading(false)
    }
  };


  return (
    <div className="registration-container">
      <div className="headingStyle">
        <h1 className="admin">Admin</h1>
        <h1 className="reg">Login</h1>
      </div>
      <p className="p">Get Access to high standards industry leads</p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <label htmlFor="field1">Enter New Password*</label> */}
          <div className="input-grouppass">
          <label style={{marginBottom: '10px', width:' 100px' , marginLeft: '10px' , color: '#333333'}} htmlFor="field2">Enter Email/Phone</label>
            <input
              type="text"
              id="firstName"
              placeholder="9000000000"
              name="password"
              value={emailMob} onChange={(e) => setEmailMob(e.target.value)}
            />
          </div>
          <div className="input-grouppass2">
          <label style={{marginBottom: '10px', width:' 4000px' , marginLeft: '30px'}}  htmlFor="field2">Enter Password*</label>
            <div>
            <input
              type="password"
              id="lastName"
              placeholder="***********"
              name="confirmPass"
              value={pass} onChange={(e) => setPass(e.target.value)}
            />
            </div>
          </div>
        </div>
        <button type="submit" onClick={handleSubmit} disabled={loading}>
        {loading ? "Loading..." : "CONFIRM"}
      </button>
      <p className='reg-p-tag'>
       Dont't have an account?</p>
    <a href=" /Register" className="link">Register</a> 
    <a href=" /ForgotPassword" className="link">Forgot password</a> 
  
    </div>
  );
}

export default Login;
