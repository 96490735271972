import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../context/userContext";

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  let location = useLocation();
  if (!user || user === null || user === undefined) {
    return <Navigate to="/register" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
