import React, { useContext, useEffect, useState } from "react";
import NavigationBar from "../../Components/NavigattionBar/NavigationBar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { useFilters, useGlobalFilter, usePagination, useTable } from "react-table";
import "./LeadScreen.css";
import Footer from "../../Components/Footer/Footer";
import { UserContext } from "../../context/userContext";
import Drawer from "../../Components/drawer/Drawer";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import OutboxIcon from '@mui/icons-material/Outbox';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { BASE_URL } from "../../assets/ApiUrls";
import { CircularProgress } from "@mui/material";
import AddBrokerModal from "../../Components/AddBrokerModal/AddBrokerModal";
import AddLeadsModal from "../../Components/AddLeadsModal/AddLeadsModal";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BulkUploadModal from "../../Components/BulkUploadModal/BulkUploadModal";
import SendBrokerModal from "../../Components/SendBrokerModal/SendBrokerModal";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const dataTable = [
 
  {
    id: 1,
    ownerName: 'Samuel Jeff',
    businessName: 'FusionTecz',
    industry: 'Advisory',
    email: 'samuel@gmail.com',
    location: 'Pennsylvania',
    revenue: '$ 62000',
    contactNo: '+01 9878689790',
    SINNo: '132576',
    EINNo: '132576'
  },
  
  ]

  const leadsColumns = [
    {
      Header: "Owner Name",
      accessor: "ownerName"
    },
    {
      Header: "Email",
      accessor: "ownerEmail"
    },
    {
      Header: "Monthly Revenue",
      accessor: "monthlyRevenue",
      Cell: ({ value }) => `$ ${value}`
    },
    {
      Header: "Industry",
      accessor: "industry"
    },
    {
      Header: "Location",
      accessor: "location"
    },
    {
      Header : "SIN no",
      accessor: "SINno"
    },
    {
      Header : "EIN no",
      accessor: "EINno"
    },
    {
      Header:<AccessTimeIcon/>,
      accessor: "createdAt",
      Cell: ({ value }) => new Date(value).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
    }
    
    ]
  
const LeadScreen = () => {
  const {drawer} = useContext(UserContext);
  const [filterInput, setFilterInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedBrokers, setSelectedBrokers] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isDeleteClicked, setIsdeleteClicked] = useState(false);
  const [ hoveredRow , setHoveredRow] = useState(null);
  const [leadsData , setLeadsData] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isBulkModalOpen , setIsBulkModalOpen] = useState(false);
  const [ isLeadSend , setIsLeadSend] = useState(false);
  const { getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setGlobalFilter,} =
    useTable({
      columns: leadsColumns,
      data: leadsData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    );
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  useEffect(() => {
    console.log("UseEffect Called -- ")
    fetchLeads()
  }, []);

  const fetchLeads = async () => {
    setIsLoading(true)
    const storedUserId = localStorage.getItem("user_id");
    try {
      const response = await fetch(`${BASE_URL}leads/getLeads?user_id=${storedUserId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch Leads");
      }
      const result = await response.json();
      console.log("Leads -- " , result.status)
      // setBrokerData(response?.data)
      if (result.status == 1 ) {
        setLeadsData(result.data); // Append new data to existing data
      } else {
        console.error("Invalid response format:", result);
      }
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false)
    }
  };
  const handleLeadSend = () => {
    setIsLeadSend(true)
    // Delete selected brokers
    // Implement your logic to delete brokers based on selectedBrokers state
    console.log("Selected Leads to delete :", selectedBrokers);
    // Clear selected brokers after deletion
    // setSelectedBrokers([]);
    // deleteLeads([...selectedBrokers]);
  };

  const handleConfirmDeleteClick = () => {
    // Delete selected brokers
    // Implement your logic to delete brokers based on selectedBrokers state
    console.log("Selected Leads to delete :", selectedBrokers);
    // Clear selected brokers after deletion
    setSelectedBrokers([]);
    deleteLeads([...selectedBrokers]);
  };

  const handleDeleteClick =() =>{
    setIsdeleteClicked(!isDeleteClicked)
  }

  const toggleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      const selectedRows = leadsData.map((lead) => {
        const { user_id: userId, ownerEmail: leadEmail } = lead;
        return { userIds: userId, leadEmails: leadEmail };
      });
      setSelectedBrokers(selectedRows);
    } else {
      setSelectedBrokers([]);
    }
  };

  // const toggleSelectBroker = (brokerId) => {
  //   if (selectedBrokers.includes(brokerId)) {
  //     // If the broker is already selected, deselect all brokers
  //     setSelectedBrokers([]);
  //   } else {
  //     // Otherwise, select the clicked broker
  //     setSelectedBrokers([brokerId]);
  //   }
  // };

  async function deleteLeads(dataArray) {
    console.log("DataArray -- " , dataArray[0])
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(dataArray),
      redirect: "follow"
    };
  
    try {
      const response = await fetch(
        `${BASE_URL}leads/deleteLead`,
        requestOptions
      );
      const myResponse = await response.json();
      console.log("Leads -- " , myResponse.status)
      if(myResponse.status === 1){
        console.log("Status 1 is clicked --- ")
        setIsdeleteClicked(false)
        fetchLeads();
      }// You can return the result if needed
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error for handling at the caller side
    }
  }
  const toggleSelectBroker = (lead) => {
    const { user_id: userId, ownerEmail: leadEmail } = lead;

    const isSelected = selectedBrokers.some((broker) => broker.leadEmails === leadEmail);
  
    if (!isSelected) {
      setSelectedLeads([...selectedLeads, lead]);
    setSelectedBrokers([...selectedBrokers, { userIds: userId, leadEmails: leadEmail }]);
    } else {
      setSelectedLeads(selectedLeads.filter((selectedLead) => selectedLead._id !== lead._id));
      setSelectedBrokers(selectedBrokers.filter((broker) => broker.leadEmails !== leadEmail));
    }
  };
  
  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };

  const openSendBrokerModal = () => {

    setIsLeadSend(true);
  };

  const closeSendBrokerModal = () => {
    console.log("close ")
    setIsLeadSend(false);
  };
 
  const openBulkModal = () => {
    setIsBulkModalOpen(true);
  };

  const closeBulkModal = () => {
    console.log("close ")
    setIsBulkModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    console.log("close ")
    setIsModalOpen(false);
  };


  return (
    <div>
       { drawer ? <Drawer /> : 
    <div style={{ display: 'flex', flexDirection: 'column',  overflowX: 'auto'  , height: '150vh' , backgroundColor:'#F8F8F8' }}>
      <NavigationBar tab={3} />
      {isLoading ? ( // Conditional rendering based on loading state
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress /> {/* Display CircularProgress component while loading */}
        </div>
      ) : (
      <div style={{paddingBottom: '5vh' , flex: 1,  backgroundColor: '#FFFFFF' , width: '90%' , alignSelf: 'center' , marginTop: '5vh' , borderRadius: '20px' , marginBottom: '4vh', overflowX:"auto"}}>
      <div style={{display: 'flex' , flexDirection: 'row' , alignItems: 'center' , justifyContent: 'space-between'}}>
      <div style={{display: 'flex' , flexDirection: 'row' , alignItems: 'center'}}>
        <h2 style={{marginLeft:'2rem'}}>Leads</h2>
        {
        leadsData.length > 0?
        <div style={{color: '#D68B43' , marginLeft: '10px' , fontSize: '18px'}}>{'['}{leadsData.length}{' Results'}{']'}</div>:
        null
        }
        </div>
        <input
          value={filterInput}
          onChange={handleFilterChange}
          placeholder={"Search Leads by name"}
          style={{ marginBottom: "1rem" , width: '25rem' , border: "1px solid #9E9E9E" ,paddingTop: '13px' , paddingLeft: '20px' , marginTop: '20px' , marginLeft: '40px'}}
        />
        <div  style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'center'}}>
        <div onClick={handleDeleteClick} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , cursor: 'pointer' , backgroundColor: isDeleteClicked ? "white" : '#f2dcc6'}}>
      <div style={{fontSize: '14px' , marginLeft: '5px'}}>{isDeleteClicked ? 'Cancel' : 'Select'}</div>
      
      </div>
      {
        isDeleteClicked ? 
        <div onClick={handleConfirmDeleteClick} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , marginLeft: '10px' ,  cursor: 'pointer' , backgroundColor: '#f2dcc6' }}>
       <div>Delete Lead</div>
       {/* <SendOutlinedIcon style={{ marginLeft: '15px', height: '20px' , color: '#21272A' }} />
         */}
        </div>
        :
      <div onClick={openBulkModal} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , marginLeft: '10px' ,  cursor: 'pointer' }}>
      <OutboxIcon style={{ marginInline: '5px', height: '25px' , color: '#21272A' }} />
      <div>Bulk Upload</div>
      </div>
      }
      {
        isDeleteClicked ? 
        <div onClick={openSendBrokerModal} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , marginLeft: '10px' ,  cursor: 'pointer' , backgroundColor: '#f2dcc6' }}>
       <div>Send to Broker</div>
          <SendOutlinedIcon style={{ marginLeft: '15px', height: '20px' , color: '#21272A' }} />
        </div>
        :
      <div onClick={openModal} style={{ display: 'flex', height: '16px', left: '10%', paddingInline: '15px', paddingBlock: '10px', alignItems: 'center' , border: '1px solid #AEAEAE' , marginLeft: '10px' ,  cursor: 'pointer' }}>
      <PlaylistAddIcon style={{ marginInline: '5px', height: '25px' , color: '#21272A' }} />
      <div>Add Leads</div>
      
      </div>
      }
      </div>
        </div>{
          isBulkModalOpen ?  <BulkUploadModal isOpen={isBulkModalOpen} onClose={closeBulkModal} onSubmit={fetchLeads}  /> : isLeadSend  ? <SendBrokerModal isOpen={isLeadSend} onClose={closeSendBrokerModal} selectedBrokers={selectedLeads} onSubmit={fetchLeads}  /> : 
        <AddLeadsModal isOpen={isModalOpen} onClose={closeModal} onSubmit={fetchLeads}  />
         }
          <table className="leadTable" {...getTableProps()}>
            <thead>
              {headerGroups.map((hg) => (
                <tr {...hg.getHeaderGroupProps()}>
                  {hg.headers.map((header , index) => (
                    <th {...header.getHeaderProps()}  style={{ position: 'relative' , whiteSpace: 'nowrap' , fontWeight: '400', paddingLeft: index === 0   ? '40px' :  index === 7 ?'30px': '10' }}>
                      {index === 0 && isDeleteClicked ? (
            <div style={{ display: 'flex', alignItems: 'center'  ,width: '17px' , marginLeft: '-19px'}}>
              <input checked={selectAllChecked}
                 onChange={() => toggleSelectAll()} type="checkbox" style={{marginRight: '32px' , borderRadius: '50%'}}/>
              {header.render('Header')}
            </div>
          ) : (
            header.render('Header')
          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
               
            {
              leadsData.length ==0 ? <div style={{height: '50vh', width: '90%' , paddingTop: '10%' , position: 'absolute' ,  textAlign: 'center' , alignSelf: 'center' , alignItems: 'center' , justifyContent: 'center' , fontSize: '17px'}}>
              <InfoOutlinedIcon style={{color: 'black' , height: '14px'}}></InfoOutlinedIcon> No Data
            </div>:
            <tbody {...getTableBodyProps()}>
              {page.map((row , index) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()}
                  onMouseEnter={(e) =>{
                    setHoveredRow(index)
                  }}
                  onMouseLeave={(e) =>{
                    setHoveredRow(null)
                  }}
                  style={{ backgroundColor: hoveredRow == index ? '#f2dcc6' : 'transparent' }}
                  >
                    {row.cells.map((cell , index) => (
                      <td {...cell.getCellProps()}style={{ position: 'relative' , paddingLeft: index === 0   ? '40px' : '15px'  }}> 
                      
                      {index === 0 && isDeleteClicked && (
                      <div style={{ position: 'absolute', left: '0', top: '54%', transform: 'translateY(-50%)' }}>
                       <input type="checkbox" checked={selectedBrokers.some((leads) => leads.leadEmails === row.original.ownerEmail)}
                       onChange={() => toggleSelectBroker(row.original)}
/>

                      </div>
                    )}
                    <div style={{ marginLeft: index === 0 && isDeleteClicked ? '50px' : '0' }}>
                      {cell.render('Cell')}
                    </div>
                    </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
           }
          </table>
          <div style={{  display: "flex", justifyContent: "center" , alignItems: 'center'  , marginTop:leadsData.length ==0 ? '300px': '20px'}}>
          <span style={{border: "1px solid #C4CDD5" ,display: 'flex' ,backgroundColor: canPreviousPage ? '#919EAB' : 'white'  , borderRadius: '6px' , paddingInline: '5px' , paddingBlock: '7px' , alignItems: 'center' , justifyContent: 'center' , marginRight: '10px'}} onClick={() => previousPage()} disabled={!canPreviousPage}>
            <ArrowBackIosIcon style={{marginLeft: '8px' , color: '#C4CDD5'}} />
          </span>{" "}
          <span>
            Page{" "}
            <strong>
              {state.pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span style={{ border: "1px solid #C4CDD5" , marginLeft: '10px' , display: 'flex' ,backgroundColor: canNextPage ? '#919EAB' : 'white' , borderRadius: '6px' , paddingInline: '5px' , paddingBlock: '7px' , alignItems: 'center' , justifyContent: 'center'}} onClick={() => nextPage()} disabled={!canNextPage}>
            <ArrowForwardIosIcon style={{marginLeft: '8px' , color: '#C4CDD5'}} />
          </span>{" "}
        </div>
      </div>)}
      <Footer style={{ marginTop: 'auto' }} />
    </div>}
    </div>
    
  );
};

export default LeadScreen;
