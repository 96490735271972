import React , { useEffect, useState } from 'react';
import './BulkUploadModal.css'
import CloseIcon from '@mui/icons-material/Close';
import { BASE_URL } from '../../assets/ApiUrls';
import Papa from 'papaparse';

const BulkUploadModal = ({ isOpen, onClose  , onSubmit}) => {
  
  const [brokerName , setBrokerName] = useState("");
  const [response, setResponse] = useState("");
  const [brokerEmail , setBrokerEmail] = useState("");
  const [brokerPhone , setBrokerPhone] = useState("");
  const [brokerCompany , setBrokerCompany] = useState("");
  const [location , setLocation] = useState("");
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [csvData  , setCSVData] = useState([]);

  

  useEffect(() => {
    console.log("UseEffect Called -- ")
    const storedUserId = localStorage.getItem("user_id");
    console.log("UseEffect Called -- " , storedUserId)

    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  if (!isOpen) return null;


  const addBroker = async (event) => {
    const storedUserId = localStorage.getItem("user_id");
    console.log("CSV DATA ---- " , csvData)
    const updatedCsvData = csvData.slice(0, -1);
    setIsLoading(true)
    console.log("Clicked " , userId)
    if (updatedCsvData.length == 0) {
      alert("All Fields are required!");
      setIsLoading(false)
      return; // Exit the function early
    }
    event.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({"leads" : updatedCsvData})
    };

    try {
      const response = await fetch(`${BASE_URL}leads/createLeadCSV?user_id=${storedUserId}`, requestOptions);
      const data = await response.json();
      setResponse(data);
      console.log("Post Data ----- " , requestOptions.body)
      console.log("Response -- - " , data)
      if (data.status === 1) {
        console.log("Response -- - " , data)
        onSubmit();
        onClose()
      }
      else{
        alert(data.message)
      }
    } catch (error) {
      console.error(error);
    }finally {
      setIsLoading(false)
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        console.log("CSV DATA ---- ", results.data);
        setCSVData(results.data); // Set the parsed CSV data to state
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
  };

  return (
    <div className="bulkmodal-overlay">
      <div className="bulkmodal">
        <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' , alignItems: 'center'}}>
        <h3>Upload Leads</h3>
        <CloseIcon className="close-btn" onClick={onClose} />
        </div>
        <div>Download the skeleton file to either copy the field columns or put all your user leads on the same file and upload it down below.</div>
        
          {/* <label>
            Name:
            <input type="text" />
          </label> */}
      
      </div>
      <div className="bulkrows">
        <input type='file' accept='.csv' onChange={handleFileUpload} style={{alignSelf: 'center' , marginLeft: '15px' , marginTop: '60px'}} />
      <button style={{cursor: "pointer" , marginTop: '110px' , width: '300px' }} className='buttons' onClick={addBroker
          } disabled={isLoading} type="submit">{isLoading ? "Loading..." : "Confirm"}</button>
      
      </div>
    </div>
  );
};

export default BulkUploadModal;
