import React, { useEffect, useState } from 'react';
import './SendBrokerModal.css';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../assets/ApiUrls';

const SendBrokerModal = ({ isOpen, onClose , onSubmit , selectedBrokers}) => {
  const navigate = useNavigate();
  const [response, setResponse] = useState("");
  const [notes , setNotes] = useState("");
  const [amount , setAmount] = useState();
  const [status , setStatus] = useState("Pending");
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [brokerData , setBrokerData] = useState([]);
  const [brokerToPay, setBrokerToPay] = useState(null);

  useEffect(() => {
    fetchBrokers()
    console.log("Selected Leads  -- " , selectedBrokers)
    const storedUserId = localStorage.getItem("user_id");

    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  const fetchBrokers = async () => {
    setIsLoading(true)
    const storedUserId = localStorage.getItem("user_id");
    try {
      const response = await fetch(`${BASE_URL}brokers/getBrokers?user_id=${storedUserId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch brokers: ${response.status} ${response.statusText}`);
      }
      const result = await response.json(); // Parse response as JSON
      console.log("Fetched brokers:", result.status); // Check the structure of result
      
      // Check if 'data' property exists and is an array
      if (result.status == 1) {
        setBrokerData(result.data); // Append new data to existing data
      } else {
        console.error("Invalid response format:", result);
      }
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }finally{
      setIsLoading(false)
    }
  };



  if (!isOpen) return null;


  const createRecord = async (event) => {
    const storedUserId = localStorage.getItem("user_id");
    // console.log("BrokerTOPay" , brokerToPay , " - " , brokerToPay.brokerName , " - " , brokerToPay.brokerEmail , ' userID ' , userId );
    setIsLoading(true)
    console.log("Clicked " , userId)
    if (!brokerToPay) {
      alert("Please select a broker first!");
      setIsLoading(false);
      return; // Exit the function early
    }
    event.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        leadArray: selectedBrokers,
        brokerEmail: brokerToPay?.brokerEmail,
        note: notes
    })
    };

    console.log(" POST REQUEST --- " , requestOptions.body)

    try {
      const response = await fetch(`${BASE_URL}leads/sendEmailLead?user_id=${storedUserId}`, requestOptions);
      const data = await response.json();
      setResponse(data);
      console.log("Response -- - " , data)
      if (data.status === 1) {
        console.log("Response -- - " , data)
        onSubmit();
        onClose()
      }
      else{
        alert(data.message)
        onClose()
      }
    } catch (error) {
      console.error(error);
    }finally {
      setIsLoading(false)
    }
  };

  const handleBrokerChange = (event) => {
    const selectedBrokerId = event.target.value;
    const selectedBroker = brokerData.find(broker => broker._id === selectedBrokerId);
    setBrokerToPay(selectedBroker);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
  };

  return (
    <div className="cmodal-overlaye">
      <div className="cmodale">
        <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' , alignItems: 'center'}}>
        <h3>Send Leads</h3>
        <CloseIcon className="cclose-btne" onClick={onClose} />
        </div>
       
        
        <div style={{marginBottom: '20px'}}>The status of the payment can be modified Later.</div>
        <form onSubmit={handleSubmit}>
          {/* <label>
            Name:
            <input type="text" />
          </label> */}
          {/* <input style={{width: '400px' , padding: '10px'}}   type="text" placeholder='Owner name' name="email" value={ownerName} onChange={(e) => setOwnername(e.target.value)}  /> */}
          <div class="cinput-groupse">
          <select className="c-dropdown1e" id="status" onChange={handleBrokerChange}>
              <option value="">Select Broker</option>
              {brokerData.map(broker => (
                <option key={broker._id} value={broker._id}>{broker.brokerName}</option>
              ))}
            </select>
      </div>
      
      <div className="cinput-groupse">
  <textarea 
  class="c-texArea"
    style={{ width: '885px', padding: '10px', height: '60px',  border: 'none' , marginTop: '20px'}} // Adjust height as needed
    id="email" 
    placeholder="Note for Reference (Optional)" 
    name="email" 
    value={notes} 
    onChange={(e) => setNotes(e.target.value)} 
  />
</div>

      
          <button style={{cursor: "pointer"}} className='cbuttonse' onClick={createRecord
          } disabled={isLoading} type="submit">{isLoading ? "Loading..." : "Send via Email"}</button>
        </form>
      </div>
    </div>
  );
};

export default SendBrokerModal;
