import React, { useContext, useEffect } from 'react';
import {Route, Routes,} from 'react-router-dom';
import Splash from '../Screens/SplashScreen'; // Import your Splash component
import RegisterPage from '../Screens/AuthScreen/RegisterPage/RegisterPage';
import OtpPage from '../Screens/AuthScreen/OtpPage/OtpPage';
import SetupPassword from '../Screens/AuthScreen/SetupPassword/SetupPassword';
import HomePage from '../Screens/Home/Home';
import PaymentScreen from '../Screens/Payment/PaymentScreen';
import BrokerScreen from '../Screens/Brokers/BrokerScreen';
import LeadScreen from '../Screens/Leads/LeadScreen'; 
import ProfileScreen from '../Screens/ProfileScreen/ProfileScreen';
import BrokerDetailScreen from '../Screens/BrokerDetailScreen/BrokerDetailScreen'
import Login from '../Screens/AuthScreen/LoginPage/LoginPage';
import ProtectedRoutes from "../utils/ProtectedRoutes.jsx";
import UnAuthRoutes from "../utils/UnAuthRoutes.jsx";
import { UserContext } from '../context/userContext.js';
import ForgotPassword from '../Screens/AuthScreen/ForgotPassword/ForgotPassword.jsx';
export default function Router() {
    const {setUser} = useContext(UserContext);
    useEffect(()=>{
     const user_id =  localStorage.getItem("user_id");
     const username =  localStorage.getItem("username");
      if(user_id && username)  setUser({user_id:user_id,username:username})
    },[])
  return (
    <Routes>
    <Route path="/" element={<Splash />} />
    <Route path="/register" element={<UnAuthRoutes><RegisterPage /></UnAuthRoutes>} />
    <Route path="/otpPage" element={<UnAuthRoutes><OtpPage /></UnAuthRoutes>} />
    <Route path="/setupPassword" element={<UnAuthRoutes><SetupPassword/></UnAuthRoutes>} />
    <Route path="/Login" element={<UnAuthRoutes><Login/></UnAuthRoutes>} />
    <Route path="/ForgotPassword" element={<UnAuthRoutes><ForgotPassword/></UnAuthRoutes>} />
    <Route path="/homePage" element={<ProtectedRoutes><HomePage/></ProtectedRoutes>} />
    <Route path="/PaymentScreen" element={<ProtectedRoutes><PaymentScreen/></ProtectedRoutes>} />
    <Route path="/BrokerScreen" element={<ProtectedRoutes><BrokerScreen/></ProtectedRoutes>} />
    <Route path="/LeadScreen" element={<ProtectedRoutes><LeadScreen/></ProtectedRoutes>} />
    <Route path="/ProfileScreen" element={<ProtectedRoutes><ProfileScreen/></ProtectedRoutes>} />
    <Route path="/BrokerDetailScreen" element={<ProtectedRoutes><BrokerDetailScreen/></ProtectedRoutes>} />
              {/* <Route path="/home" component={Home} /> */}
    </Routes>
  )
}
