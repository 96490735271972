import React, { useContext } from "react";
import { UserContext } from "../context/userContext";
import { Navigate, useLocation } from "react-router-dom";

const UnAuthRoutes = ({ children }) => {
  const { user } = useContext(UserContext);
  let location = useLocation();
  if (user && user !== null && user !== undefined) {
    console.log("User -- " , user)
    return <Navigate to="/homePage" state={{ from: location }} replace />;
  }
  return children;
};

export default UnAuthRoutes;
