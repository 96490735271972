// useDataTable.js
import { useTable } from "react-table";
import React from "react";

const useDataTable = (data, columns) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  };
};

export default useDataTable;
